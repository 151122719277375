import { render, staticRenderFns } from "./CookieCategoryModalForm.vue?vue&type=template&id=2879b01d"
import script from "./CookieCategoryModalForm.vue?vue&type=script&lang=js"
export * from "./CookieCategoryModalForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports